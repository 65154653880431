<template>
  <div class="search">
    <el-input placeholder="请输入关键词搜索产品文档" v-model="keyword" @keyup.enter.native="search">
      <i slot="suffix" class="iconfont icon-desc cursor" @click="search">&#xe77d;</i>
    </el-input>
    <!-- <el-button @click="search">搜索</el-button> -->
  </div>
</template>

<script>
export default {
  props: {
    val: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      keyword: this.val
    }
  },
  methods: {
    search() {
      if (this.keyword) {
        this.$emit('update:val', this.keyword)
        this.$emit('search')
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.search {
  display: flex;
  .icon-desc {
    color: @mainColor;
    line-height: 40px;
    padding-right: 14px;
    &:hover {
      color: #1034b5;
    }
    &:active {
      color: #061f8f;
    }
  }
  /deep/ .el-input {
    width: 590px;
    // box-shadow: @boxShadow;
    &__inner {
      border-radius: 4px;
      &::placeholder {
        color: @placeHolderColor;
      }
    }
  }
  /deep/ .el-input__inner:focus {
    border-color: @mainColor;
  }
  .el-button {
    width: 110px;
    border: none;
    background: @mainColor;
    color: #fff;
    font-size: 16px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
</style>
