<template>
  <div class="card">
    <div class="card_item" v-for="item in cardData" :key="item.id">
      <img :src="item.imgPath" />
      <h4 class="font">{{ item.title }}</h4>
      <span>{{ item.describe }}</span>
      <div class="font-margin">
        <span class="font-color cursor" @click="jumpDoc(item.leftBtn.id)">{{ item.leftBtn.label}}</span>
        <span
          class="font-color cursor"
          @click="jumpDoc(item.centerBtn.id)"
        >{{ item.centerBtn.label}}</span>
        <span class="font-color cursor" @click="jumpDoc(item.rightBtn.id)">{{ item.rightBtn.label}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    jumpDoc(value) {
      if (value !== 'github') {
        this.$router.push({
          name: 'docs/show',
          params: {
            id: value
          }
        })
      } else {
        window.open('https://github.com/vhall/VHYun_SDK_Full_Demo', '_blank')
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.card {
  display: flex;
  flex-wrap: wrap;
  &_item {
    width: 334px;
    height: 188px;
    padding: 20px 24px 0;
    background: @whiteBcgColor;
    border: 1px solid @borderColor;
    border-radius: 4px;
    margin: 0 24px 24px 0;
    position: relative;
    img {
      width: 44px;
      height: 44px;
    }
    span {
      font-size: 14px;
      color: #6e7a87;
    }
    .font-margin {
      position: absolute;
      bottom: 20px;
      .font-color {
        color: @mainColor;
        padding-right: 20px;
      }
    }
    .font {
      font-size: 16px;
      font-weight: 600;
      color: @hoversColor;
      margin: 13px 0 8px 0;
    }
  }
  &_item:hover {
    box-shadow: @boxShadow;
  }
}
</style>
