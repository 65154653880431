<template>
  <div class="document-center">
    <c-header></c-header>
    <div class="document-center_pd">
      <div class="document-center_search">
        <div>
          <h1 class="font-size">文档中心</h1>
          <span class="font-color">查看微吼云官方文档，详细了解微吼云产品介绍、集成指南、使用方法以及相关功能及接口说明</span>
          <c-search :val.sync="keyword" @search="search" class="search-mg"></c-search>
        </div>
        <div class="document-center_img">
          <img src="../../assets/images/documnet-icon.png" />
        </div>
      </div>
    </div>
    <div class="document-center_card">
      <div
        class="card-desc cursor"
        v-for="item in descCardValue"
        :key="item.id"
        @click="JumpDoc(item.id)"
      >
        <img class="img-desc" :src="item.imgPath" alt />
        <span class="font-color">{{item.label}}</span>
      </div>
    </div>

    <div class="document-center_service">
      <h2 class="fontColor">服务</h2>
      <div>
        <c-card :cardData="serveCardData"></c-card>
      </div>
    </div>
    <div class="document-center_scenes">
      <h2 class="fontColor">场景方案</h2>
      <div>
        <c-card :cardData="scenesCardData"></c-card>
      </div>
    </div>
    <back-top></back-top>
    <!-- <div class="document-center__nav">
      <h3>文档中心</h3>
      <c-search :val.sync="keyword" @search="search"></c-search>
    </div>
    <div class="document-center__service-list c-width">
      <div class="service-list__item">
        <i class="icon-desc"></i>
        <h3>平台介绍</h3>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 2 } }"
            >平台概述</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 6 } }"
            >新手指南</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 4 } }"
            >购买指导</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 5 } }"
            >微吼云服务协议</router-link
          >
        </p>
      </div>
      <div class="service-list__item">
        <i class="icon-product"></i>
        <h3>产品文档</h3>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 23 } }"
            >直播服务开发指南</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 89 } }"
            >点播服务开发指南</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 179 } }"
            >文档白板服务开发指南</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 136 } }"
            >音视频互动服务开发指南</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 233 } }"
            >聊天服务开发指南</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 573 } }"
            >表单服务开发指南</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 863 } }"
            >邮件服务开发指南</router-link
          >
        </p>
      </div>
      <div class="service-list__item">
        <i class="icon-source"></i>
        <h3>开发者资源</h3>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 385 } }"
            >直播SDK资源</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 386 } }"
            >点播SDK资源</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 384 } }"
            >文档白板SDK资源</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 387 } }"
            >音视频互动SDK资源</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 383 } }"
            >聊天SDK资源</router-link
          >
        </p>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 676 } }"
            >表单SDK资源</router-link
          >
        </p>
        <p><span @click="toApiTest" class="text-btn">API在线测试</span></p>
      </div>
      <div class="service-list__item">
        <i class="icon-solution"></i>
        <h3>解决方案文档</h3>
        <p>
          <router-link :to="{ name: 'docs/show', params: { id: 391 } }"
            >直播答题SDK</router-link
          >
        </p>
      </div>
    </div>-->
  </div>
</template>

<script>
import cHeader from '../layout/components/header'
import cSearch from './components/search'
import cCard from './components/card.vue'
import backTop from '../layout/components/backToTop.vue'
import { getCookieToken } from '@/utils'
export default {
  components: { cHeader, cSearch, cCard, backTop },
  data() {
    return {
      keyword: '',
      apiTestHref: '',
      isLogin: false,
      descCardValue: [
        {
          id: '3',
          label: '平台概述',
          imgPath: require('../../assets/images/doc/doc-platform.png')
        },
        {
          id: '5',
          label: '新手指南',
          imgPath: require('../../assets/images/doc/doc-new-guide.png')
        },
        {
          id: '71',
          label: '购买指南',
          imgPath: require('../../assets/images/doc/doc-buy-guide.png')
        },
        {
          id: '72',
          label: '微吼云服务协议',
          imgPath: require('../../assets/images/doc/doc-service.png')
        }
      ],
      serveCardData: [
        {
          id: '0',
          title: '直播服务',
          describe:
            '实现基于Web、H5、iOS、Android各端的推断流以及观看功能,提供流畅的直播体验',
          imgPath: require('../../assets/images/doc/doc-live-streaming.png'),
          leftBtn: {
            id: '73',
            label: 'API文档'
          },
          centerBtn: {
            id: '173',
            label: 'SDK文档'
          },
          rightBtn: {
            id: '706',
            label: 'Demo体验'
          }
        },
        {
          id: '1',
          title: '点播服务',
          describe:
            '丰富的媒体处理及媒资管理功能集,完善的回放管理以及视频管理能力,提供极致的观看体验',
          imgPath: require('../../assets/images/doc/doc-dibbling@2x.png'),
          leftBtn: {
            id: '190',
            label: 'API文档'
          },
          centerBtn: {
            id: '701',
            label: 'SDK文档'
          },
          rightBtn: {
            id: '693',
            label: 'Demo体验'
          }
        },
        {
          id: '2',
          title: '文档白板服务',
          describe: '更灵活的文档白板操作,支持多种画笔格式及组合',
          imgPath: require('../../assets/images/doc/doc-white-list.png'),
          leftBtn: {
            id: '569',
            label: 'API文档'
          },
          centerBtn: {
            id: '626',
            label: 'SDK文档'
          },
          rightBtn: {
            id: '621',
            label: 'Demo体验'
          }
        },
        {
          id: '3',
          title: '聊天服务',
          describe:
            '聊天消息实时发送,支持私聊、群聊等多种聊天方式；支持自定义消息类型,满足聊天室内的多种互动模式',
          imgPath: require('../../assets/images/doc/doc-chat.png'),
          leftBtn: {
            id: '352',
            label: 'API文档'
          },
          centerBtn: {
            id: '413',
            label: 'SDK文档'
          },
          rightBtn: {
            id: '707',
            label: 'Demo体验'
          }
        },
        {
          id: '4',
          title: '互动服务',
          describe:
            '基于webrtc实现的音视频互动服务,最多支持16人连麦互动,并支持旁路直播,提供实时清晰的音视频会话',
          imgPath: require('../../assets/images/doc/doc-interactive-service.png'),
          leftBtn: {
            id: '481',
            label: 'API文档'
          },
          centerBtn: {
            id: '554',
            label: 'SDK文档'
          },
          rightBtn: {
            id: '549',
            label: 'Demo体验'
          }
        },
        {
          id: '5',
          title: '表单服务',
          describe:
            '提供基础的表单创建、收集的能力,集合多样化的题型,满足业务层多种题型的变化要求。',
          imgPath: require('../../assets/images/doc/doc-form.png'),
          leftBtn: {
            id: '281',
            label: 'API文档'
          },
          centerBtn: {
            id: '330',
            label: 'SDK文档'
          },
          rightBtn: {
            id: '329',
            label: 'Demo体验'
          }
        },
        {
          id: '6',
          title: '推送通知服务',
          describe:
            '服务集成了短信和邮件两大信息发送服务,他们建构在可靠稳定的云基础之上,能快速实现信息的推送通知。',
          imgPath: require('../../assets/images/doc/doc-push.png'),
          leftBtn: {
            id: '350',
            label: 'API文档'
          },
          centerBtn: {
            id: '',
            label: ''
          },
          rightBtn: {
            id: '',
            label: ''
          }
        },
        {
          id: '7',
          title: '数据服务',
          describe:
            '为直播、点播、互动、表单、文档、聊天和推送通知服务提供统计数据查询服务。',
          imgPath: require('../../assets/images/doc/data-serve.png'),
          leftBtn: {
            id: '772',
            label: 'API文档'
          },
          centerBtn: {
            id: '',
            label: ''
          },
          rightBtn: {
            id: '',
            label: ''
          }
        }
      ],
      scenesCardData: [
        {
          id: '0',
          title: '互动直播',
          describe:
            '直播间内进行多人连麦互动,实时推送互动消息,集成微吼云服务能力,快速实现互动直播。',
          imgPath: require('../../assets/images/doc/doc-interactive.png'),
          leftBtn: {
            id: '555',
            label: '立即体验'
          },
          centerBtn: {
            id: 'github',
            label: '示例代码'
          },
          rightBtn: {
            id: '',
            label: ''
          }
        },
        {
          id: '1',
          title: '营销带货',
          describe:
            '直播过程中实时人、货、场数据监控，根据数据波动及场控提示，快速调整直播策略。',
          imgPath: require('../../assets/images/doc/tada-target.png'),
          leftBtn: {
            id: '817',
            label: '立即体验'
          },
          centerBtn: {
            id: '',
            label: ''
          },
          rightBtn: {
            id: '',
            label: ''
          }
        }
      ]
    }
  },
  created() {
    this.clearCache()
    this.isLogin = getCookieToken()
  },
  methods: {
    clearCache() {
      if (this.$route.query.all_clear && this.$route.query.all_clear == 1) {
        this.$fetch('clearCache', { all_clear: 1 })
          .then((res) => { })
          .catch((e) => {
            console.log(e)
          })
      }
    },
    toApiTest() {
      const jump_path = encodeURIComponent(
        `${window.urlConfig.docUrl}test/api/test`
      )
      decodeURIComponent(jump_path)
      if (this.isLogin) {
        this.apiTestHref = decodeURIComponent(jump_path)
      } else {
        this.apiTestHref = `${window.urlConfig.consoleUrl}loginLayout/login`
        document.cookie = `login_jump_path=${decodeURIComponent(
          jump_path
        )};domain=.vhallyun.com;path=/`
      }
      location.href = this.apiTestHref
    },
    search() {
      this.$router.push({ name: 'search', params: { keyword: this.keyword } })
    },
    JumpDoc(value) {
      if (value) {
        this.$router.push({
          name: 'docs/show',
          params: {
            id: value
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../assets/less/varible.less';
.document-center {
  .width {
    max-width: 1283px;
    margin: 0 auto;
    padding-left: 83px;
  }
  &_pd {
    height: 400px;
    background: #f6f7f9 url(../../assets/images/document-banner.png) no-repeat
      right center;
    background-size: contain;
  }
  &_search {
    .width;
    .font-size {
      font-size: 40px;
      font-weight: 600;
      color: @hoversColor;
      margin-bottom: 20px;
    }
    .font-color {
      color: #5c6670;
    }
    padding: 76px 0 0 83px;
    display: flex;
    justify-content: space-between;

    .search-mg {
      margin-top: 32px;
    }
    img {
      width: 320px;
      margin: 25px 83px 0 0;
      // padding-right: 83px;
    }
  }
  &_card {
    .width;
    display: flex;
    position: relative;
    top: -42px;
    .card-desc {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      background: linear-gradient(180deg, #f6f7f9 0%, @whiteBcgColor 100%);
      box-shadow: @boxShadow;
      border-radius: 4px;
      border: 2px solid @whiteBcgColor;
      width: 188px;
      height: 84px;
      &:hover {
        span {
          color: @mainColor;
        }
      }
      .img-desc {
        width: 26px;
        height: 26px;
        padding-right: 8px;
      }
      .font-color {
        font-size: 18px;
        font-weight: 500;
        color: @hoversColor;
      }
    }
  }
  &_service {
    .width;
  }
  &_scenes {
    .width;
  }
  .fontColor {
    color: @hoversColor;
  }
}
</style>
